<template lang="pug">
  .cookie-agreement
    .cookie-content
      p You must be 21+ years of age to access our website. 
      p By accessing this site, you agree to our <span><a href="/legal/terms-of-use">Terms of Use</a></span> and the use of cookies as described in our <span> <a href="/legal/privacy-policy">Privacy Policy.</a></span>
      button.button(v-on:click="consentToCookies") Agree
</template>

<script>
export default {
  name: 'CookieConsent',
  mounted () {
    let cookiesConsented = this.$cookies.get("phylos_cookiesConsented")
    if (cookiesConsented === 'true') {
      document.querySelector('.cookie-agreement').setAttribute('class', 'cookie-agreement hidden')
    }
  },
  methods: {
    consentToCookies () {
      this.$cookies.set("phylos_cookiesConsented", true, "30d")
      let cookiesConsented = this.$cookies.get("phylos_cookiesConsented")
      if (cookiesConsented === 'true') {
        document.querySelector('.cookie-agreement').setAttribute('class', 'cookie-agreement hidden')
      }
    }
  }
}
</script>

<style lang="scss">
@import '../styles/helpers/variables.scss';

.cookie-agreement {
  background-color: $black-tint;
  bottom: 2%;
  left: 2%;
  position: fixed;
  width: 25%;
  padding: 1%;
  z-index: 90000;
  &.hidden {
    display: none;
  }
  .cookie-content {
    padding: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: $white;
    a {
      color: $white;
      text-decoration: underline;
    }
    button {
      background-color: $green;
      border-radius: 5px;
      border-style: none;
      color: $white;
      font-family: $primary-light;
      font-size: 16px;
      padding: 0.75rem 1rem;
      transition: ease-in-out 0.2s;
      transition-timing-function: cubic-bezier(.17,.67,.83,.67);
      &:hover {
        background-color: $green-shade;
        color: $white;
        cursor: pointer;
      }
    }
  }
}

@media (max-width: $breakpoint-medium) {
  .cookie-agreement {
    width: 50%;
  }
}
</style>
