<template>
  <div class="expander">
    <input v-bind:id="question.replace(/ /g,'-')" class="expander-toggle" type="checkbox" tabindex=0>
    <label v-bind:for="question.replace(/ /g,'-')" class="expander-label">
      {{question}}
    </label>
    <div class="expander-content">
      <slot></slot>
    </div>
  </div>
</template>


<script>
export default {
  name: 'Expander',
  props: ['question']
}
</script>


<style lang="scss">
@import '../styles/helpers/rem.scss';
@import '../styles/helpers/variables.scss';
@import '../styles/helpers/mixins.scss';

.expander {
  @include prefix(transition, box-shadow 0.2s);
  background-color: $smoke;
  box-shadow: 0 1px 1px $transparent-9;
  margin-bottom: 10px;

  .expander-toggle {
    opacity: 0;

    & + .expander-label {
      color: $teal;
      cursor: pointer;
      display: block;
      font-size: 18px;
      line-height: 21px;
      margin-bottom: 5px;
      padding: 2px 45px 14px 20px;
      position: relative;
      user-select: none;

      + .expander-content {
        @include prefix(transition, max-height 0.3s ease);
        max-height: 0;
        overflow-y: hidden;

        p {
          color: $black-tint;
          margin: 0 20px 20px;
          white-space: pre-wrap;
        }
        strong {
          font-weight: bold;
        }
        img {
          max-height: 25rem;
          max-width: 100%;
        }
      }

      &::after {
        background: url('/img/icons/carat-down.png') center center no-repeat;
        content: '';
        height: 15px;
        position: absolute;
        right: 25px;
        width: 15px;
      }
    }
    &:focus {
      & + .expander-label {
        outline: 5px auto -webkit-focus-ring-color;
      }
    }

    &:checked {
      & + .expander-label {
        & + .expander-content {
          max-height: 1000px;
        }
        &::after {
          background: url('/img/icons/carat-up.png') center center no-repeat;
        }
      }
    }
  }
}
</style>
