<template lang="pug">
  footer.footer
    .wrapper
      a.icon(href='/')
        img.footer-logomark.svg(src='/img/logos/phylos-logomark-green.svg', alt='Phylos Bioscience')
      nav(role='navigation')
        ul.footer-nav
          li(v-for="link in links.data.footer_links").footer-nav-item
            a(:href="link.footer_link_url") {{ link.footer_link_label }}
      nav(role='navigation')
        ul.footer-nav.social
          li(v-for="link in links.data.footer_social_links").footer-nav-item
            h4 Follow us on LinkedIn!
              br
              a(:href="link.footer_social_link_url")
                i(:class="'fab ' + link.footer_social_link_icon")
      #copyright
        p {{ links.data.footer_copyright }}
        p {{ links.data.footer_disclaimer }}
</template>

<script>
export default {
  name: 'SiteFooter',
  props: ['footerlinks'],
  computed: {
    links: function(){
      return JSON.parse(this.footerlinks)
    }
  }
}
</script>

<style lang="scss">
@import '../styles/helpers/rem.scss';
@import '../styles/helpers/variables.scss';
@import '../styles/helpers/mixins.scss';

.footer {
  border-top: 1px solid $grey-tint;
  text-align: center;
  a {
    color: $black-tint;
  }
  .footer-logomark {
    margin-bottom: 50px;
    width: 60px;
  }
  .footer-nav {
    list-style-type: none;
    margin: 0 0 50px 0;
    padding: 0;
    &.social {
      margin-bottom: 0px;
    }
    .footer-nav-item {
      display: inline-block;
      margin: 0 15px;
      .fa-facebook-f {
        &:hover {
          color: #3b5998;
        }
      }
      .fa-twitter {
        &:hover {
          color: #1da1f2;
        }
      }
      .fa-instagram {
        &:hover {
          color: #c13584;
        }
      }
      .fa-linkedin-in {
        &:hover {
          color: #0077b5;
        }
      }
    }
    &:first-child {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
      margin: {
        left: auto;
        right: auto;
      }
      .footer-nav-item {
        margin: 4px 8px;
      }
    }
  }
  #copyright {
    p {
      font-size: 1rem;
      margin-top: 2rem;
      &:nth-child(2) {
        font-size: 0.8rem;
        line-height: 1.5;
        margin-bottom: 0;
      }
    }
  }
}
</style>
