<template>
<p>
  <a tabindex="0" @click="toggleExpand()" @keyup.enter="toggleExpand()"> {{ expandHide }} All <i :class="'fas fa-caret-' + caretDirection"></i></a>
</p>
</template>

<script>
export default {
  name: 'ExpandToggle',
  data () {
    return {
      checked: false
    }
  },
  computed: {
    expandHide() {
      if (this.checked) {
        return 'Hide';
      } else {
        return 'Expand';
      }
    },
    caretDirection() {
      if (this.checked) {
        return 'up';
      } else {
        return 'down';
      }
    }
  },
  methods: {
    toggleExpand() {
      this.checked = !this.checked;
      document.querySelectorAll('input.expander-toggle').forEach(expanderToggle => {
        expanderToggle.checked = this.checked;
      })
    }
  }
}
</script>

<style lang="scss" scoped>
p {
  font-size: 1rem;
  margin-bottom: 0;
  text-align: right;
  a:hover {
    cursor: pointer;
  }
}
</style>
