<template lang="pug">
  header.header-new
    .wrapper
      .header-logo
        a(href='/')
          img.header-logo-icon.svg(src='/img/logos/logo.svg', alt='Phylos Bioscience')
        #skip
          a(href='#main') Skip to Main Content
        .mobile-button(@click='classToggle', @keyup.enter='classToggle', role='button', tabindex='0', aria-haspopup='true')
          .hamburger-menu
      .nav-wrapper
        .nav-main
          nav.header-nav
            ul.header-nav-menubar(role='menubar')
              li(v-for="link in links").header-nav-item
                input.submenu-toggle(:id="link.primary.header_submenu_title.toLowerCase()", aria-haspopup='true', :aria-label="link.primary.header_submenu_title", name='submenu', tabindex='0', type='checkbox', role='menuitem', v-if="link.slice_type === 'header_link_menu'")
                ul.header-subnav(role='menu', v-if="link.slice_type === 'header_link_menu'")
                  li.header-subnav-item(role='none', v-for="submenuLink in link.items")
                    h3.submenu-category(v-if="submenuLink.header_submenu_link_subcategory_title") {{ submenuLink.header_submenu_link_subcategory_title }}
                    a.galaxy-desktop(v-if="submenuLink.header_submenu_link_url === '/search'", :href="submenuLink.header_submenu_link_url", role='menuitem') {{ submenuLink.header_submenu_link_label }}
                    a.galaxy-mobile(v-else-if="submenuLink.header_submenu_link_url === '/search'", :href="submenuLink.header_submenu_link_url", role='menuitem') {{ submenuLink.header_submenu_link_label }}
                    a.nested-menu-header(v-else-if="submenuLink.has_nested_menu" tabindex='0' @click="showNestedMenu(submenuLink.header_submenu_link_label)" @keyup="showNestedMenu(submenuLink.header_submenu_link_label)" aria-haspopup='true') {{ submenuLink.header_submenu_link_label }}
                      i.fal.fa-angle-right
                      i.fal.fa-angle-down(:id="createNestedMenuId(submenuLink.header_submenu_link_label) + '-caret'")
                    a(v-else, :href="submenuLink.header_submenu_link_url", role='menuitem') {{ submenuLink.header_submenu_link_label }}
                    ul.nested-menu(v-if="submenuLink.has_nested_menu && nestedMenu.primary.nested_menu_submenu_label == submenuLink.header_submenu_link_label" v-for="nestedMenu in nestedLinks" :id="createNestedMenuId(submenuLink.header_submenu_link_label)")
                      li(v-for="nestedLink in nestedMenu.items")
                        a(:href="nestedLink.nested_link_url") {{ nestedLink.nested_link_label }}
                label.dropdown(:for="link.primary.header_submenu_title.toLowerCase()", aria-hidden='true', v-if="link.slice_type === 'header_link_menu'")
                  span(class="dropdown-carat products-submenu-title", v-if="link.primary.header_submenu_title === 'Products'") {{ link.primary.header_submenu_title }}
                  span(class="dropdown-carat", v-else) {{ link.primary.header_submenu_title }}
                label.single-link-label(v-if="link.slice_type === 'header_link_single'")
                  a(:href="link.primary.header_link_url", role='menuitem', class='single-link') {{ link.primary.header_link_label }}
</template>

<script>
export default {
  name: 'SiteHeader',
  props: ['headerlinks', 'user'],
  computed: {
    links: function() {
      let nonNestedLinks = [];
      JSON.parse(this.headerlinks).forEach(link => {
        if (link.slice_type != 'header_nested_menu') {
          nonNestedLinks.push(link);
        }
      })
      return nonNestedLinks;
    },
    nestedLinks: function() {
      let nestedLinks = [];
      JSON.parse(this.headerlinks).forEach(link => {
        if (link.slice_type == 'header_nested_menu') {
          nestedLinks.push(link);
        }
      })
      return nestedLinks;
    },
    currentUser: function() {
      if (this.user) {
        return JSON.parse(this.user);
      } else {
        return null;
      }
    }
  },
  methods: {
    classToggle: function () {
      document.documentElement.classList.toggle('mobile-active-landing');
      document.querySelector('.hamburger-menu').classList.toggle('animate');
      window.scrollTo(0, 0);
    },
    showNestedMenu(menuId) {
      let selectedMenu = this.createNestedMenuId(menuId);
      document.querySelector('#' + selectedMenu).classList.toggle('showing');
      document.querySelector('#' + selectedMenu + '-caret').classList.toggle('fa-angle-up');
    },
    createNestedMenuId(menuId) {
      return menuId.toLowerCase().split(' ').join('-');
    }
  }
}
</script>

<style lang="scss">
@import '../styles/helpers/rem.scss';
@import '../styles/helpers/variables.scss';
@import '../styles/helpers/mixins.scss';
@mixin submenuPositioning {
  display: block;
  position: absolute;
  top: 1rem;
}
.header-new {
  background-color: $white;
  border-bottom: 1px solid $grey-tint;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 8888;
  .wrapper {
    display: flex;
    height: 86px;
    justify-content: space-between;
    padding: rem(20px);
    width: 100%;
    .header-logo {
      width: 9.5rem;
      img {
        height: 2.6rem;
      }
    }
    .nav-wrapper {
      width: 100%;
    }
    .nav-main {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    .mobile-button {
      display: none;
    }
    #skip {
      a {
        height: 1px;
        left: -10000px;
        overflow: hidden;
        position: absolute;
        top: auto;
        width: 1px;
        &:focus {
          height: auto;
          position: static;
          top: 4px;
          width: auto;
        }
      }
    }
  }
  .header-nav {
    display: flex;
    flex: 1;
    justify-content: center;
    .header-nav-menubar {
      align-items: center;
      display: flex;
      justify-content: space-between;
      list-style-type: none;
      padding-left: 0;
      width: 68%;
      a {
        font-family: $primary-regular;
        font-size: 0.75rem;
        letter-spacing: 0.125rem;
        text-transform: uppercase;
      }
      .dropdown-carat {
        align-items: center;
        display: flex;
        justify-content: center;
        &::after {
          background: url('/img/icons/carat-down.png') center center no-repeat;
          content: ' ';
          display: inline-block;
          height: 1rem;
          position: relative;
          right: rem(-4px);
          transition: ease 0.2s;
          vertical-align: middle;
          width: rem(15px);
        }
      }
      .header-subnav {
        background-color: $brown;
        display: none;
        line-height: 1.5;
        list-style-type: none;
        padding: 1.5rem 0;
        text-align: left;
        width: 15rem;
        a {
          color: $white;
          padding: 0.1rem 0px;
        }
        .header-subnav-item {
          display: flex;
          flex-direction: column;
          justify-content: center;
          position: relative;
          h3 {
            color: darken($brown-shade, 10%);
            flex: 1;
            font-family: $primary-semibold;
            font-size: 0.75rem;
            letter-spacing: 0.125rem;
            margin-bottom: 0;
            margin-top: 1rem;
            padding: 0 1.25rem;
            text-transform: uppercase;
          }
          a {
            flex: 1;
            padding: 0.4rem 1.25rem;
            &:focus {
              background-color: $brown-tint;
            }
            &.galaxy-mobile {
              display: block;
            }
            &:hover {
              background-color: $brown-tint;
              cursor: pointer;
            }
            &.nested-menu-header {
              align-items: center;
              display: flex;
              justify-content: space-between;
              &:hover {
                cursor: default;
              }
              i {
                font-size: 1.25rem;
              }
            }
          }
          &:first-child {
            h3 {
              margin-top: 0;
            }
          }
          .nested-menu {
            background: $brown;
            display: none;
            left: 100%;
            list-style: none;
            padding: 1.5rem 0 1.5rem;
            position: absolute;
            top: 0;
            width: 15rem;
            li {
              display: flex;
            }
            &.showing {
              display: block;
            }
          }
          
          .fa-angle-down {
            display: none;
          }
          
          @media only screen and (min-width: 1041px) {
            &:hover {
              .nested-menu {
                display: block;
              }
            }
          }
        }
      }
      .header-nav-item {
        align-items: center;
        display: flex;
        justify-content: center;
        position: relative;
        a {
          text-decoration: none;
        }
        label {
          font-family: $primary-regular;
          font-size: 0.75rem;
          height: 1rem;
          letter-spacing: 0.125rem;
          text-transform: uppercase;
        }
        ul, li {
          padding-left: 0;
        }
        .single-link-label {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        &:hover {
          ul {
            @include submenuPositioning;
          }
          .dropdown-carat {
            &:hover {
              color: $brown;
            }
            &::after {
              background: url('/img/icons/carat-up.png') center center no-repeat;
            }
          }
        }
      }
      .submenu-toggle {
        height: 0px;
        opacity: 0;
        width: 0px;
        &:focus {
          & ~ label {
            outline: 5px auto -webkit-focus-ring-color;
          }
        }
        &:checked {
          & + ul {
            @include submenuPositioning;
          }
        }
      }
      .single-link {
        color: $black;
        &:hover {
          color: $brown;
        }
      }
    }
  }
  .header-register {
    align-items: center;
    display: flex;
    .fa-user {
      margin-right: 0.5rem;
    }
  }
}
@media only screen and (max-width: 1040px) {
  .header-new {
    .wrapper {
      background-color: $white;
      display: flex;
      height: auto;
      flex-direction: column;
      padding: 0;
      .mobile-button {
        display: block;
      }
      .header-logo {
        background-color: $white;
        border-bottom: 1px solid $grey-tint;
        display: flex;
        justify-content: space-between;
        padding: rem(20px);
        position: fixed;
        width: 100%;
        z-index: 9000;
      }
      .nav-wrapper {
        background-color: $brown;
        display: none;
        padding: 6.5rem 0;
      }
      .nav-main {
        display: flex;
        flex-direction: column-reverse;
        .header-nav {
          display: block;
        }
        .header-nav-menubar {
          align-items: flex-start;
          flex-direction: column;
          width: 100%;
          a, label {
            color: $white;
          }
          .header-nav-item {
            align-items: flex-start;
            flex-direction: column-reverse;
            position: static;
            width: 100%;
            label {
              font-size: 1.15rem;
              margin-bottom: 1.5rem;
              padding: 0.65rem 1.25rem;
            }
            label[for='products'] {
              display: none;
            }
            .products-submenu-title {
              display: none;
            }
            .single-link-label {
              padding-left: 0;
              width: 100%;
            }
            .single-link {
              font-size: 2rem;
              padding: 0.5rem 1.25rem;
              width: 100%;
              &:hover {
                background-color: $brown-tint;
                color: $white;
              }
            }
            &:hover {
              position: static;
              ul {
                position: static;
              }
              .dropdown-carat {
                color: $white;
              }
            }
            &:last-child {
              margin-top: 1rem;
              padding: 0;
            }
          }
          .header-subnav {
            background-color: transparent;
            display: block;
            padding-top: 0;
            text-align: left;
            width: 100%;
            h3 {
              color: $white;
              font-family: $primary-regular;
              font-size: 1.15rem;
              padding: 0.65rem 1.25rem;
            }
            a {
              font-size: 2rem;
              margin-bottom: 0.5rem;
              padding-bottom: 0.65rem;
              padding-top: 0.65rem;
              &.nested-menu-header > i {
                font-size: 1.75rem;
              }
              &.galaxy-mobile {
                display: block;
              }
              &.galaxy-desktop {
                display: block;
              }
            }
            .nested-menu {
              padding: 0;
              position: static;
              width: 100%;
              li > a {
                padding-left: 2.5rem;
              }
            }
            .fa-angle-right {
              display: none;
            }
            .fa-angle-down, .fa-angle-up {
              display: block;
            }
          }
        }
        .header-register {
          justify-content: flex-start;
          margin-bottom: 2rem;
          padding-left: 1.25rem;
        }
      }
      .dropdown-carat {
        &::after {
          opacity: 0;
        }
      }
    }
  }
}
</style>
