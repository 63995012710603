<template>
  <div class="modal">
    <button v-if="video" v-on:click="displayVideoModal" class="modal-button">
      <i class="fal fa-play-circle fa-lg"></i>{{action}}
    </button>
    <button v-else v-on:click="displayModal" class="modal-button">
      {{action}}
    </button>
    <div class="modal-overlay" id="leader" v-show="modalVisible">
      <div v-if="video" class="modal-popup">
        <p class="modal-close" v-on:click="displayVideoModal" tabindex="0" v-on:keyup.enter="displayVideoModal" role="button" aria-label="close">
          <i class="fal fa-times"></i>
        </p>
        <div class="plyr__video-embed">
          <youtube :video-id="video" ref="youtube"></youtube>
        </div>
      </div>
      <div v-else class="modal-popup">
        <p class="modal-close" v-on:click="displayModal" tabindex="0" v-on:keyup.enter="displayModal" role="button" aria-label="close">
          <i class="fal fa-times"></i>
        </p>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import VueYoutube from 'vue-youtube';

export default {
  name: 'Modal',
  props: ['action','video'],
  data () {
    return {
      modalVisible: false
    }
  },
  computed: {
    player() {
      return this.$refs.youtube.player;
    }
  },
  methods: {
    displayModal () {
      this.modalVisible = !this.modalVisible;
    },
    displayVideoModal () {
      this.displayModal();
      let heroWrapper = document.querySelector('.hero-wrapper');
      if (this.modalVisible) {
        this.player.playVideo();
        if (heroWrapper !== null) {
          heroWrapper.setAttribute('class', 'wrapper hero-wrapper modal-popup-active');
        }
      } else {
        this.player.pauseVideo();
        if (heroWrapper !== null) {
          heroWrapper.setAttribute('class', 'wrapper hero-wrapper');
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import '../styles/helpers/rem.scss';
@import '../styles/helpers/variables.scss';
@import '../styles/helpers/mixins.scss';

.modal {
  .modal-button {
    cursor: pointer;
    .fa-lg {
      line-height: 1;
    }
    &:focus {
      outline: 5px auto -webkit-focus-ring-color;
    }
  }
  .modal-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
    opacity: 1;
    z-index: 9999;
  }
  .modal-popup {
    margin: 70px auto;
    padding: 30px;
    background: #fff;
    border-radius: 5px;
    width: 80%;
    position: relative;
    transition: all 1s ease-in-out;
    z-index: 9999;
  }
  .modal-close {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
  }
  @media (min-width: $breakpoint-small) {
    .modal {
      .modal-popup {
        width: 50%;
      }
    }
  }
  @media (min-width: $breakpoint-large) {
    .modal {
      .modal-popup {
        width: 30%;
      }
    }
  }
}
</style>
