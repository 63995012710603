<template>
  <div class="typeahead">
    <div class="typeahead-input-wrapper">
      <input
        autocorrect="off"
        autocapitalize="off"
        class="typeahead-input"
        type="text"
        placeholder="Search the Phylos Galaxy"
        role="search"
        @input='evt=>query=evt.target.value'
        v-bind:value='query'
        v-on:keyup="suggestTerms"
        v-on:keyup.enter="keyEnter"
        v-on:keyup.up="keyUp"
        v-on:keyup.down="keyDown"
        v-bind:autofocus="autofocuson"
        >
      <div class="typeahead-button">
        <i class="fal fa-search" v-show="showMagnify"></i>
        <i class="fal fa-times" v-show="showClose" v-on:click="clearQuery"></i>
      </div>
    </div>
    <div class="typeahead-terms" v-show="showSearching">
      <ul>
        <li class="searching">
          Searching For: <span>{{ query }}</span>
        </li>
        <li
          v-show="showTerms"
          v-for="(term, i) in terms"
          :key="i"
          v-on:click="clickTerm"
          :class="{ 'active': i === arrowCount }"
          >{{ term }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'Typeahead',
  props: {
    autofocuson: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      query: '',
      showMagnify: true,
      showClose: false,
      showSearching: false,
      showTerms: false,
      terms: [],
      arrowCount: -1
    }
  },
  created () {
    if (location.search.length >= 3) {
      this.query = decodeURI(location.search.split('=')[1]);
    }
  },
  mounted() {
    document.addEventListener('click', this.clickOutside);
  },
  destroyed() {
    document.removeEventListener('click', this.clickOutside);
  },
  methods: {
    suggestTerms() {
      var vm = this;
      vm.showSearching = true;
      vm.showMagnify = false;
      vm.showClose = true;
      if (vm.query.length <= 0) {
        vm.showSearching = false;
        vm.showMagnify = true;
        vm.showClose = false;
      } if (vm.query.length >= 3) {
        axios.get('/sims/api/terms?q=' + vm.query)
          .then(response => {
            vm.terms = response.data.data;
          })
          .catch(error => {
            console.log(error)
          });
        vm.showTerms = true;
      } else {
        vm.showTerms = false;
      }
    },
    clickTerm (e) {
      var regex = '&amp;';
      this.query = e.target.innerHTML.replace(regex, '&');
      this.showTerms = false;
      this.addQuery();
    },
    clearQuery () {
      this.query = '';
      this.hideTerms();
      this.showClose = false;
      this.showMagnify = true;
    },
    hideTerms () {
      this.showTerms = false;
      this.showSearching = false;
    },
    keyDown () {
      if (this.arrowCount < this.terms.length) {
        this.arrowCount = this.arrowCount + 1;
      }
    },
    keyUp () {
      if (this.arrowCount > 0) {
        this.arrowCount = this.arrowCount -1;
      }
    },
    keyEnter (e) {
      if (this.terms[this.arrowCount]) {
        this.query = this.terms[this.arrowCount];
        this.arrowCount = -1;
        this.addQuery();
      } else {
        this.addQuery();
      }
    },
    addQuery() {
      var location = window.location.protocol + "//" + window.location.host + '/search?q=' + encodeURI(this.query);
      window.location = location;
    },
    updateQuery() {
      if (history.pushState) {
        var addQuery = window.location.protocol + "//" + window.location.host + window.location.pathname + '?q=' + encodeURI(this.query);
        window.history.pushState({path:addQuery},'',addQuery);
      }
    },
    clickOutside(e) {
      if (!this.$el.contains(e.target)) {
        this.hideTerms();
        this.arrowCount = -1;
      }
    }
  }
}
</script>

<style lang="scss">
@import '../styles/helpers/rem.scss';
@import '../styles/helpers/variables.scss';
@import '../styles/helpers/mixins.scss';

.typeahead {
  max-width: 768px;
  position: relative;
  width: 100%;
  z-index: 7777;
  .typeahead-input-wrapper {
    @include radius();
    @include shadow();
    border-bottom: 1px solid $grey-tint;
    position: relative;
    .typeahead-input {
      @include radius();
      background-color: $white;
      box-sizing:border-box;
      border: none;
      border-radius: none;
      color: $grey-shade;
      font-size: rem(16px);
      padding: 20px 30px;
      width: 100%;
      &:focus {
        outline: none;
      }

    }
    ::placeholder {
      color: $grey-shade;
    }
    .typeahead-button {
      position: absolute;
      right: 20px;
      top: 15px;
      .fal {
        fill: currentColor;
        font-size: rem(30px);
        &.fa-search {
          color: $green;
        }
        &.fa-times {
          color: $black-tint;
          cursor: pointer;
        }
      }
    }
  }

  .typeahead-terms {
    background-color: $brown;
    color: $grey;
    position: absolute;
    width: 100%;
    ul {
      @include resetList();
      cursor: pointer;
      li {
        color: $white;
        display: none;
        padding: 5px 10px;
        &.searching {
          font-weight: bold;
          text-transform: uppercase;
          span {
            font-weight: normal;
            text-transform: none;
          }
        }
        &:nth-child(-n+10) {
          display: block;
        }
        &:hover {
          background-color: $brown-tint;
        }
        &.active {
          background-color: $brown-tint;
        }
      }
    }
  }
}
</style>
