var resize = () => {
  let screensize = window.innerWidth
  if (screensize <= 1040) {
    document.body.classList.toggle('mobile');
  } else {
    document.body.classList.toggle('desktop');
    document.documentElement.classList.remove('mobile-active');
    document.querySelector('html').classList.remove('mobile-active-landing');
    document.querySelector('.hamburger-menu').classList.remove('animate');
  }
}

window.onload = resize;
window.onresize = resize;
