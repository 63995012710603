<template>
  <section class="mobile-galaxy" v-show="showMeta">
    <div class="wrapper">
      <div class="results">

        <div v-show="loading" class="loading">
          <i class="fal fa-circle-notch fa-spin"></i>
        </div>

        <div class="error" v-if="noResults || tooShort">
          <div v-if="noResults">
            <i class="fal fa-frown"></i>
            <h2>No Results Found</h2>
            <p>Try changing the search term.</p>
          </div>
          <div v-else-if="tooShort">
            <i class="fal fa-meh"></i>
            <h2>Query Too Short</h2>
            <p>Try increasing the search term characters.</p>
          </div>
        </div>

        <ul class="result-list">
          <li v-if="showMeta" class="result-list-results">
            <span>{{ resultsMeta.count }} results for "{{ resultsMeta.query }}"</span>
          </li>
          <li v-for="result in results" :key="result.id" :class="result.identifier">
            <a v-if="result.link" v-bind:href="result.link">
              <div v-if="result.type == 'variety'">
                <i class="fal fa-cannabis"></i>
                <span class="result-title">{{ result.name }}</span>
                <span class="result-subtitle" v-if="result['clonal-unit-variety-count'] > 1">Clone Group: {{ result['clonal-unit-name'] }}</span>
                <span class="result-subtitle">{{ result.organization }}</span>
              </div>
              <div v-else-if="result.type == 'organization'">
                <i class="fal fa-users"></i>
                <span class="result-title">{{ result.name }}</span>
                <span class="result-subtitle">{{ result["variety-count"] }} Varieties</span>
              </div>
            </a>
            <a v-else v-bind:href="'/clone-group/' + result['clonal-unit-galaxy-id'] + '/'">
              <i class="fal fa-clone"></i>
              <span class="result-title">Clone Group: {{ result['clonal-unit-name'] }}</span>
              <span class="result-subtitle">{{ result['clonal-unit-galaxy-id'] }} - {{ result['clonal-unit-variety-count'] }} Cuts</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Search',
  data () {
    return {
      showIntro: true,
      loading: false,
      noResults: false,
      tooShort: false,
      showMeta: false,
      results: [],
      resultsMeta: []
    }
  },
  created () {
    if (location.search.length >= 5) {
      this.showIntro = false;
      this.loading = true;
      this.query = location.search.replace(/%20/g, ' ').split('=')[1];
      this.searchResults();
    } else if (location.search.length == 0) {
    } else {
      this.tooShort = true;
      this.showIntro = false;
    }
  },
  methods: {
    searchResults () {
      var vm = this;
      if (this.query.length >= 2) {
        axios.get('/sims/api/search?q=' + vm.query)
        .then(response => {
          vm.resultsMeta = response.data.meta;
          vm.results = response.data.data;
          vm.loading = false;
          vm.showMeta = true;
          if (response.data.data == '') {
            vm.noResults = true;
            vm.showMeta = false;
          }
        })
        .catch(error => {
          vm.tooShort = true;
          vm.loading = false;
          vm.showMeta = false;
        });
      }
    }
  }
}
</script>

<style lang="scss">
@import '../styles/helpers/rem.scss';
@import '../styles/helpers/variables.scss';
@import '../styles/helpers/mixins.scss';

.results {
  max-width: 768px;
  width: 100%;
  .introContent {
    .search-hero {
      @include bgCover();
      background-image: url('/img/banners/mobile-galaxy.jpg');
      padding: 100px 30px 50px;
      h1 {
        color: $white;
        margin: 0 0 10px;
        padding: 0;
        text-align: left;
      }
      p {
        color: $white;
        margin: 0;
        max-width: 330px;
      }
    }
    .certified {
      background-color: $brown;
      position: relative;
      a {
        display: block;
        padding:20px 30px;
        &:hover {
          color: $white;
          text-decoration: none;
        }
      }
      img {
        float: left;
        width: 120px;
      }
      strong {
        color: $white;
        display: block;
        font-size: rem(18px);
        margin: 0 0 15px;
        text-align: left;
        white-space: nowrap;
      }
      p {
        color: $white;
        margin: 0 20px 0 90px;
        max-width: 500px;
        @media only screen and ( max-width: 520px ) {
          margin-bottom: 10px;
        }
      }
      .fa-angle-right {
        color: $white;
        display: block;
        float: right;
        font-size: rem(34px);
        margin-left: 20px;
        margin-top: 30px;
        @media only screen and ( min-width: 520px ) {
          margin-top: 20px;
        }
      }
    }
  }
  .error {
    margin-top: 40px;
    text-align: center;
    h2 {
      color: $grey-shade;
    }
    .fal {
      fill: currentColor;
      font-size: rem(100px);
      &.fa-frown {
        color: $grey-tint;
      }
      &.fa-meh {
        color: $grey-tint;
      }
    }
    p {
      color: $grey;
      font-size: rem(16px);
      text-align: center;
    }
  }
  .result-list {
    background-color: $white;
    color: $grey-shade;
    margin: 0;
    padding: 0;
    li {
      border-bottom: 1px solid $grey-tint;
      overflow: hidden;
      &.result-list-results {
        span {
          color: $brown;
          display: block;
          font-family: $primary-regular;
          font-size: rem(18px);
          padding: 20px;
          text-align: center;
        }
      }
      &:last-child {
        border-bottom: none;
      }
      a {
        color: $black-tint;
        display: block;
        font-size: rem(18px);
        font-weight: bold;
        padding: 10px 30px;
        text-decoration: none;
        img {
          float: left;
          margin-right: 10px;
          width: 100px;
        }
        p {
          //float: left;
          font-size: rem(18px);
          margin: -14px 0 0 33px;
        }
        span {
          clear: both;
          display: block;
          margin-left: 33px;
          &.result-title {

          }
          &.result-subtitle {
            color: $grey-shade;
            font-size: rem(14px);
          }
        }
        strong {
          font-weight: bold;
        }
      }

    }
    .svg {
      fill: $green-tint;
      margin-right: 10px;
      position: relative;
      top: 14px;
      width:20px;
    }
    .fal {
      position: relative;
      top: 24px;
      &.fa-cannabis {
        color: $green-tint;
      }
      &.fa-clone {
        color: $teal-tint;
      }
      &.fa-users {
        color: $brown;
        margin-right: 6px;
      }
    }
  }
}
</style>
