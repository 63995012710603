// Styles
import '@fortawesome/fontawesome-pro/css/all.css'
import 'vue-plyr/dist/vue-plyr.css';
import './styles/index.scss';

// Vendors
//import $ from './js/vendor/jquery-3.3.1.min';
//import Axios from './js/vendor/axios.min';

// Scripts
import inlineSVG from 'inline-svg';
import loginMenu from './js/login-menu';
import reorderMobile from './js/reorder-mobile';
import simpleParallax from 'simple-parallax-js';

// Inline SVGs
inlineSVG.init({
  svgSelector: 'img.svg'
});


/**  
 * Selects all hero images and applies the simpleParallax 
 * module when client browser is a mobile device.
 */ 
const mediaQuery = window.matchMedia('(max-width: 768px)')

if (mediaQuery.matches) {
  document.addEventListener('DOMContentLoaded', function () {
    var images = document.querySelectorAll('.hero-image');
    new simpleParallax(images, {
      scale: 1.1
    });
  })
}


// Vue
import Vue from 'vue'
import Axios from 'axios'
import VuePlyr from 'vue-plyr'
import VueYoutube from 'vue-youtube'
import VueSimpleSVG from 'vue-simple-svg'
import VueCookies from 'vue-cookies'

Vue.use(VueSimpleSVG)
Vue.use(VueYoutube)
Vue.use(VuePlyr)
Vue.use(VueCookies)
Vue.prototype.$http = Axios

import Expander from './components/Expander.vue'
import ExpandToggle from './components/ExpandToggle.vue'
import Modal from './components/Modal.vue'
import Results from './components/Results.vue'
import Typeahead from './components/Typeahead.vue'
import SiteFooter from './components/SiteFooter.vue'
import SiteHeader from './components/SiteHeader.vue'
import CookieConsent from './components/CookieConsent.vue'

new Vue({
  el: '#app',
  components: {
    Expander,
    ExpandToggle,
    Modal,
    Results,
    Typeahead,
    SiteFooter,
    SiteHeader,
    CookieConsent
  }
});
